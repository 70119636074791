import React, { useEffect, useState } from 'react'
import Layout from '../../components/Global/Layout'
import Img from 'gatsby-image'
import Loader from '../../components/Global/Loader'
import { graphql, Link } from 'gatsby'
import _ from 'lodash'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { SCHEMATYPES } from '../../constants'

export const query = graphql`
  query ($link: String!, $localeRegex: String!) {
    contentfulKnowledgeBaseArticle(
      slug: { eq: $link }
      node_locale: { eq: "en-US" }
    ) {
      title
      quote
      content0 {
        internal {
          content
        }
      }
      content1 {
        internal {
          content
        }
      }
      image {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imagesMobile {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      type
      seoContent {
        internal {
          content
        }
      }
      createdAt
      updatedAt
      node_locale
      slug
      actionCard {
        internal {
          content
        }
      }
    }

    allContentfulKnowledgeBaseArticle(
      filter: {
        node_locale: { eq: "en-US" }
        slug: { regex: $localeRegex, ne: $link }
      }
    ) {
      edges {
        node {
          title
          type
          image {
            fixed(width: 82, height: 55, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
          slug
          updatedAt(formatString: "MMMM Do, YYYY")
        }
      }
    }

    allContentfulKnowledgeBaseLanding {
      edges {
        node {
          relatedArticlesTitle
          shareArticleText
          protectYourBrandCard {
            title
            btn
          }
          trademarkCountry
          node_locale
        }
      }
    }
  }
`

function processElement(content) {
  var element = []
  content.map((object, index) => {
    switch (object.type) {
      case 'title':
        element.push(
          <h5
            className='mt-3 pt-3 mb-0'
            key={index}
            dangerouslySetInnerHTML={{ __html: object.content }}
          ></h5>
        )
        break
      case 'body':
        element.push(
          <p
            className='article-text mb-2'
            key={index}
            dangerouslySetInnerHTML={{ __html: object.content }}
          ></p>
        )
        break
      case 'list':
        var tempList = []
        _.forEach(object.content, function (listItem, index) {
          tempList.push(
            <li dangerouslySetInnerHTML={{ __html: listItem }} key={index}></li>
          )
        })
        element.push(
          <ul className='article-list' key={index}>
            {tempList}
          </ul>
        )
        break
      case 'quote':
        element.push(
          <div className='my-3 my-md-4'>
            <hr className='qoute-hr' />
            <p
              className='article-qoute'
              dangerouslySetInnerHTML={{ __html: object.content }}
            ></p>
            <hr />
          </div>
        )
        break
      default:
        break
    }
  })

  return element
}

const KnowledgeArticle = (props) => {
  const [content0, setContent0] = useState([])
  const [content1, setContent1] = useState([])

  useEffect(() => {
    setContent0(
      JSON.parse(
        props.data?.contentfulKnowledgeBaseArticle?.content0?.internal?.content
      )
    )
    setContent1(
      JSON.parse(
        props.data?.contentfulKnowledgeBaseArticle?.content1?.internal?.content
      )
    )
  }, [])

  let locale = 'en-US'
  if (props.data?.contentfulKnowledgeBaseArticle?.slug?.includes('en-ca')) {
    locale = 'en-CA'
  }

  const pageTextData =
    props.data?.allContentfulKnowledgeBaseLanding?.edges.filter(
      (node) => node.node.node_locale === locale
    )[0]?.node

  var jsonSEO = null
  try {
    jsonSEO = JSON.parse(
      props.data?.contentfulKnowledgeBaseArticle?.seoContent.internal.content
    )
  } catch (error) {
    jsonSEO = null
  }

  if (content0?.body == null) {
    return (
      <Layout
        url='/articles'
        locale={locale}
        title={`${jsonSEO.title} | Heirlume`}
        desc={jsonSEO.desc}
        pageImg={props.data?.contentfulKnowledgeBaseArticle?.image?.fluid.src}
      >
        <Loader loading />
      </Layout>
    )
  }

  const actionCard = props.data?.contentfulKnowledgeBaseArticle?.actionCard
    ?.internal?.content
    ? JSON.parse(
      props.data?.contentfulKnowledgeBaseArticle?.actionCard?.internal
        ?.content
    )
    : pageTextData?.protectYourBrandCard

  return (
    <Layout
      locale={locale}
      title={`${jsonSEO.title} | Heirlume`}
      desc={jsonSEO.desc}
      pageImg={props.data?.contentfulKnowledgeBaseArticle?.image?.fluid.src}
      strucData={{
        type: SCHEMATYPES.ARTICLE,
        articleData: {
          url: props.data?.contentfulKnowledgeBaseArticle?.slug,
          createdAt: props.data?.contentfulKnowledgeBaseArticle?.createdAt,
          updatedAt: props.data?.contentfulKnowledgeBaseArticle?.updatedAt
        }
      }}
    >
      <Container className='banner-background px-0' fluid>
        <Container>
          <Row>
            <Col className='col-11 offset-1' md={{ span: 11, offset: 1 }}>
              <h1 className='h1'>
                {props.data?.contentfulKnowledgeBaseArticle?.title}
              </h1>
              <p
                className='tm-country'
                dangerouslySetInnerHTML={{
                  __html: pageTextData.trademarkCountry
                }}
              ></p>
            </Col>
          </Row>
          <Row className='justify-content-end mt-4'>
            <Col className='col-12 px-0 px-md-3' md='11'>
              <Img
                className='d-none d-md-block'
                fluid={props.data?.contentfulKnowledgeBaseArticle?.image?.fluid}
              />
              <Img
                className='d-block d-md-none'
                fluid={
                  props.data?.contentfulKnowledgeBaseArticle?.imagesMobile
                    ?.fluid
                }
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='bg-bottom-tree py-4 pt-md-5'>
        <Row className='justify-content-center'>
          <Col
            className='col-10 order-2 order-md-2'
            md={{ offset: '1', span: '3' }}
          >
            <p className='font-weight-bold'>
              {pageTextData?.relatedArticlesTitle}
            </p>
            <hr />
            {_.shuffle(
              _.keys(props.data?.allContentfulKnowledgeBaseArticle?.edges)
            )
              .slice(0, 3)
              .map((type, index) => {
                return (
                  <Link
                    to={
                      props.data?.allContentfulKnowledgeBaseArticle?.edges[type]
                        ?.node.slug
                    }
                    key={index}
                  >
                    <div className='d-flex justify-content-between custom-link-size'>
                      <p className='pr-3'>
                        {
                          props.data?.allContentfulKnowledgeBaseArticle?.edges[
                            type
                          ]?.node.title
                        }
                      </p>
                      <Img
                        fixed={
                          props.data?.allContentfulKnowledgeBaseArticle?.edges[
                            type
                          ]?.node.image.fixed
                        }
                      />
                    </div>
                    <hr />
                  </Link>
                )
              })}
            <div className='d-flex justify-content-between'>
              <p className='font-weight-bold'>
                {pageTextData?.shareArticleText}
              </p>
              <div className='d-flex'>
                <a
                  className='share-icon mr-3'
                  href={`https://www.facebook.com/sharer/sharer.php?u=${document.location.href}`}
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Share on Twitter'
                >
                  <div className='fb'></div>
                </a>
                <a
                  className='share-icon'
                  href={`https://twitter.com/intent/tweet?url=${document.location.href}`}
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Share on Facebook'
                >
                  <div className='twitter'></div>
                </a>
              </div>
            </div>
          </Col>
          <Col className='col-10 order-1 order-md-2' md='8'>
            {processElement(content0?.body)}
            <div className='d-md-flex align-items-start justify-content-between my-3 my-md-4'>
              <div className='col-md-12 px-0'>
                {props.data?.contentfulKnowledgeBaseArticle?.quote ? (
                  <>
                    <hr className='qoute-hr' />
                    <p
                      className='article-qoute'
                      dangerouslySetInnerHTML={{
                        __html:
                          props.data?.contentfulKnowledgeBaseArticle?.quote
                      }}
                    ></p>
                    <hr />
                  </>
                ) : null}
              </div>
            </div>
            {processElement(content1?.body)}
            <div className='card rounded-0 border-0 protect-card my-5 mt-5 mb-md-0'>
              <div className='card-body my-3 px-5 px-md-4 py-5 py-md-5 d-md-flex justify-content-between align-items-center'>
                <h5 className=''>{actionCard.title}</h5>
                <Button
                  className='px-5 py-2'
                  as={Link}
                  variant='light'
                  to={
                    actionCard.url
                      ? `/${locale.toLowerCase()}/${actionCard.url}`
                      : `/${locale.toLowerCase()}/pricing`
                  }
                >
                  {actionCard.btn}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default KnowledgeArticle
